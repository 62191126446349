import React from 'react';
import ReactDOM from 'react-dom/client';
// import '@openfonts/mali_all';
// import '@openfonts/open-sans-condensed_all';
// import '@openfonts/kanit_all';
// import '@openfonts/open-sans_all';
import App from './App';
// import CssBaseline from '@mui/material/CssBaseline';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {/* <CssBaseline /> */}
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
