import React from "react";
import axios from "axios";

const App = () => {
  React.useEffect(() => {
    const PartName = window.location.pathname;
    const urlParams = PartName.substring(1, PartName.length);

    axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/api/redirectpage/geturl/${urlParams}`)
      .then((response) => {
        window.location.href = response.data.url;
      }).catch(err => {
        window.location.href = "https://www.pizzahut.co.th";
      });
  }, []);

  return (
    <>
    </>
  );
};

export default App;